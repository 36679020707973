import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { NavHashLink } from 'react-router-hash-link';
import ReactPlayer from 'react-player/youtube'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { Navigation, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';

import { Button } from '@mui/material';
import TabLayout from '../../components/tabs';


import AppLayout from '../../layouts/app/AppLayout';
import './home.scss';
import './bannerAnimation.scss';
import AppIcons from '../../assets/images/icons';
import SVGIcons from '../../assets/images/icons/svgIcons';

import { translateThis } from '../../helpers/language.helper';
import PPPlatformAppHeader from './ppPlatformHeader';
import SliderImage from './ppPlatformSlider/ppPlatformSlider';
import SliderImageWeb from './ppPlatformSlider/ppPlatformSliderweb';
import PPContactForm from './PPcontactForm/PPContactForm';
import { appConfigAtomState } from '../../state/reducerAtoms/app.atom';

const PPPlatformHomePage = () => {
    const appStateAs = useRecoilValue(appConfigAtomState);
    const [appState, setAppState] = useState({
        selectedLang: appStateAs?.appStateAs || ''
    })
    useEffect(() => {
        setAppState(appStateAs);
        document.body.classList.add('pp-platform-home');
    }, [appStateAs]);

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [openReport, setOpenReport] = useState(false);

    const handleOpenReport = () => {
        setOpenReport(true);
    };
    const handleCloseReport = () => {
        setOpenReport(false);
    };


    const [openPPForm, setOpenPPForm] = useState(false);

    const handleOpenPPForm = () => {
        setOpenPPForm(true);
    };
    const handleClosePPForm = () => {
        setOpenPPForm(false);
    };



    const parkingSliderItems = [
        {
            Image: AppIcons.parkingSlider1,
            // content: <><p>{translateThis('Your facility')} <b>{translateThis('100% Digital')}</b> {translateThis('on')} <b>{translateThis('Mobile')}</b></p></>,
            content: <><p>{translateThis('Your facility')} <b>{translateThis('100% Digital')}</b></p></>,
        },
        {
            Image: AppIcons.parkingSlider10,
            // content: <><p>{translateThis('Attract clients with a catchy')} <b>{translateThis('Website')}</b></p></>,
            content: <><p>{translateThis('Own catchy')} <b>{translateThis('Website')}</b></p></>,
        },
        {
            Image: AppIcons.parkingSlider11,
            // content: <><p>{translateThis('From web to forms, all with')} <b>{translateThis('your logo')}</b></p></>,
            content: <><p><b>{translateThis('Your logo')}</b> {translateThis('on every form')} </p></>,
        },
        {
            Image: AppIcons.parkingSlider2,
            // content: <><p>{translateThis('All')} <b>{translateThis('forms')}</b> & <b>{translateThis('daily comms')}</b> {translateThis('online')}</p></>,
            content: <><p>{translateThis('Fill')} <b>{translateThis('all forms')}</b> {translateThis('online')}</p></>,
        },
        {
            Image: AppIcons.parkingSlider3,
            // content: <><p>{translateThis('Fill all')} <b>{translateThis('Care4Kids')} </b>{translateThis('forms online')}</p></>,
            content: <><p>{translateThis('Fill all')} <b>{translateThis('Care4Kids')} </b>{translateThis('online')}</p></>,
        },
        {
            Image: AppIcons.parkingSlider4,
            content: <><p>{translateThis('Bilingual')} <b>{translateThis('English')}</b> & <b>{translateThis('Spanish')}</b></p></>,
        },
        {
            Image: AppIcons.parkingSlider5,
            content: <><p>{translateThis('Accept')} <b>{translateThis('credit card')}</b> {translateThis('payments')}</p></>,
        },
        {
            Image: AppIcons.parkingSlider6,
            content: <><p>{translateThis('Share')} <b>{translateThis('news, events')}</b> & <b>{translateThis('pictures')}</b></p></>,
        },
        {
            Image: AppIcons.parkingSlider7,
            content: <><p>{translateThis('Receive')} <b>{translateThis('messages')}</b> & <b>{translateThis('notifications')}</b></p></>,
        },
        {
            Image: AppIcons.parkingSlider8,
            content: <><p>{translateThis('Children daily')} <b>{translateThis('check-ins / outs')}</b></p></>,
        },
        {
            Image: AppIcons.parkingSlider9,
            content: <><p>{translateThis('Manage your')} <b>{translateThis('staff members')}</b> {translateThis('online')}</p></>,
        },
    ];

    const FeatureList = [
        {
            title: 'Efficiency',
            content: <><p>{translateThis('EfficiencyPara_1')}</p> <p>{translateThis('EfficiencyPara_2')}</p> <p>{translateThis('EfficiencyPara_3')}</p></>,
            button: <><Button onClick={handleOpen} className='feature-btn'>{translateThis('Learn more')}</Button></>,
            Image: AppIcons.ppFeatures1,
        },
        {
            title: 'Billing & payments',
            content: <><p>{translateThis('BillingPara_1')}</p> <p>{translateThis('BillingPara_2')}</p> <p>{translateThis('BillingPara_3')}</p></>,
            Image: AppIcons.ppFeatures2,
        },
        {
            title: 'Reports',
            content: <><p>{translateThis('ReportsPara_1')}</p> <p>{translateThis('ReportsPara_2')}</p> <p>{translateThis('ReportsPara_3')}</p></>,
            button: <><Button onClick={handleOpenReport} className='feature-btn'>{translateThis('Learn more')}</Button></>,
            Image: AppIcons.ppFeatures3,
        },
        {
            title: 'featureCommunications',
            content: <><p>{translateThis('featureCommunicationsPara_1')}</p> <p>{translateThis('featureCommunicationsPara_2')}</p> <p>{translateThis('featureCommunicationsPara_3')}</p></>,
            Image: AppIcons.ppFeatures4,
        },
        {
            title: 'featureAttendance',
            content: <><p>{translateThis('featureAttendancePara_1')}</p> <p>{translateThis('featureAttendancePara_2')}</p> <p>{translateThis('featureAttendancePara_3')}</p></>,
            Image: AppIcons.ppFeatures5,
        },

    ];

    const PlanningList = [
        {
            title: translateThis('Childcare Master'),
            Image: AppIcons.ChildcareMaster,
            subTitle: translateThis('Everything on'),
            subTitle2: translateThis('Growing stars'),
            descriptionList: <><ul>
                <li>{translateThis('Employees clock-in / out')}</li>
                <li>{translateThis('Dashboard with metrics')}</li>
                <li>{translateThis('Customize portal colors')}</li>
            </ul></>,
            benefitTemplates: translateThis('7 Website templates'),
            languages: translateThis('English & Spanish'),
            button: <><Link className='plans-btn' to={'https://buy.stripe.com/bIYdUl0aN6RD2Iwg0c'} target='_blank'>{translateThis('Reserve with $99')}</Link></>,
        },
        {
            title: translateThis('GrowingStars'),
            Image: AppIcons.GrowingStars,
            subTitle: translateThis('Everything on'),
            subTitle2: translateThis('Blossoming'),
            descriptionList: <><ul>
                <li>{translateThis('plan_Pricing')}</li>
                <li>{translateThis('plan_Daily_communications')}</li>
            </ul></>,
            benefitTemplates: translateThis('5 Website templates'),
            languages: translateThis('English & Spanish'),
            button: <><Link className='plans-btn' to={'https://buy.stripe.com/eVa8A1aPrgsdaaY15g'} target='_blank'>{translateThis('Reserve with $99')}</Link></>,
        },
        {
            title: translateThis('BlossomingBuds'),
            Image: AppIcons.BlossomingBuds,
            subTitle: translateThis('Everything on'),
            subTitle2: translateThis('Baby steps'),
            descriptionList: <><ul>
                <li>{translateThis('plan_Accident report')}</li>
                <li>{translateThis('plan_Collect Payments')}</li>
            </ul></>,
            benefitTemplates: translateThis('3 Website templates'),
            languages: translateThis('English & Spanish'),
            button: <><Link className='plans-btn' to={'https://buy.stripe.com/9AQdUl1eRek56YMg09'} target='_blank'>{translateThis('Reserve with $99')}</Link></>,
        },
        {
            title: translateThis('BabySteps'),
            Image: AppIcons.BabySteps,
            descriptionList: <><ul>
                <li>{translateThis('plan_Parents report')}</li>
                <li>{translateThis('plan_Children report')}</li>
                <li>{translateThis('plan_Kid check in/out')}</li>
                <li>{translateThis('plan_Annoucements')}</li>
                <li>{translateThis('plan_Policies')}</li>
                <li>{translateThis('plan_Forms')}</li>
                <li>{translateThis('plan_Inbox')}</li>
            </ul></>,
            benefitTemplates: translateThis('2 Website templates'),
            languages: translateThis('English & Spanish'),
            button: <><Link className='plans-btn' to={'https://buy.stripe.com/5kA4jL2iV4Jvfvi29i'} target='_blank'>{translateThis('Reserve with $99')}</Link></>,
        },
    ];

    const testimonialItems = [
        {
            name: translateThis('Provider name'),
            facility: translateThis('Facility name'),
            Image: AppIcons.sliderImg,
            content: translateThis('Enjoy a discounted rate on our Full plan, make the payments in installments. \nContact us for details!'),
            rating: <><p className='rating'><span>Rating:</span><img src={AppIcons.star} alt='star' /> <img src={AppIcons.star} alt='star' /> <img src={AppIcons.star} alt='star' /> <img src={AppIcons.star} alt='star' /> <img src={AppIcons.star} alt='star' /></p></>
        },
        {
            name: translateThis('Provider name'),
            facility: translateThis('Facility name'),
            Image: AppIcons.sliderImg,
            content: translateThis('Enjoy a discounted rate on our Full plan, make the payments in installments. \nContact us for details!'),
            rating: <><p className='rating'><span>Rating:</span><img src={AppIcons.star} alt='star' /> <img src={AppIcons.star} alt='star' /> <img src={AppIcons.star} alt='star' /> <img src={AppIcons.star} alt='star' /> <img src={AppIcons.star} alt='star' /></p></>
        },
        {
            name: translateThis('Provider name'),
            facility: translateThis('Facility name'),
            Image: AppIcons.sliderImg,
            content: translateThis('Enjoy a discounted rate on our Full plan, make the payments in installments. \nContact us for details!'),
            rating: <><p className='rating'><span>Rating:</span><img src={AppIcons.star} alt='star' /> <img src={AppIcons.star} alt='star' /> <img src={AppIcons.star} alt='star' /> <img src={AppIcons.star} alt='star' /> <img src={AppIcons.star} alt='star' /></p></>
        },

    ];


    const tabNames = ["view Platform", "View websites"];
    const tabContents = [
        <div key={'view_platform'}><SliderImageWeb /></div>,
        <div key={'view_websites'}><SliderImage /> </div>,
    ];


    // custom home functions here
    const pageName = "PP platform";
    return (
        <>

            <AppLayout
                pageName={pageName} key={`PP-platform-${appState?.selectedLang}`}
                // isLoadHeader={false}
                isLoadFooter={false}
                customHeader={<PPPlatformAppHeader />}
                bodyClassName='home-main-wrp f-wrp'
            >
                <>
                    <div className='pp-platform-page-main-wrapper f-wrp'>

                        <div className='pp-platform-banner-con f-wrp'>
                            <div className='pp-platform-banner-img'>
                                <span><img src={AppIcons.ppPlatformBannerBG} alt='banner' /></span>
                            </div>
                            <div className='container'>
                                <div className='pp-platformBanner-con'>
                                    <div className='pp-platformBanner-sec-con'>
                                        {/* <span><img src={AppIcons.evolveBannerLogo} alt='logo' /></span> */}
                                        <p className='title-text'>{translateThis('ppBannerText1')}</p>
                                        <h1>{translateThis('ppBannerTitle')}</h1>
                                        <h3><b>{translateThis('ppBannerText2')}</b> {translateThis('ppBannerText2_1')}</h3>
                                        <p>{translateThis('ppBannerText3')} <span>{translateThis('ppBannerText3_1')}</span> {translateThis('ppBannerText3_2')} <b>{translateThis('ppBannerText3_3')}</b> </p>

                                        <div className='banner-btn-wrp'>
                                            <NavHashLink className='sample-btn' smooth to={'#pricing'} >
                                                {translateThis('Reserve now')}
                                            </NavHashLink>
                                            <NavHashLink className='sample-btn pln-btn' smooth to={'#tutorials'} >
                                                {translateThis('See tutorials')}
                                            </NavHashLink>
                                        </div>


                                    </div>
                                    <div className='pp-platformBanner-sec-img'>
                                        <span><img src={AppIcons.ppPlatformBannerImg} alt='banner' /></span>
                                    </div>
                                </div>
                            </div>

                            <NavHashLink className='scroll-arrow-btn' smooth to={'#benefits'} >
                                <span>{SVGIcons.ArrowDownIcon()}</span>
                            </NavHashLink>
                        </div>

                        <div className='parking-slider-wrp benefits-wrp f-wrp' id='benefits'>
                            <div className='container'>
                                <div className='benefits-wrp-header f-wrp'>
                                    <h1>{translateThis('benefitsTitle1')} <span>{translateThis('benefitsTitle2')}</span> {translateThis('benefitsTitle3')} <b>{translateThis('benefitsTitle4')}</b> {translateThis('benefitsTitle5')}</h1>
                                </div>
                                <div className="row">
                                    {parkingSliderItems.map((element, key) => (
                                        <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12' key={key}>
                                            <div className="each-parking-items f-wrp">
                                                <span><img src={element.Image} alt="user" /></span>
                                                {translateThis(element.content)}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className='video-sec-wrapper f-wrp' id='glimpse'>
                            <div className='container'>
                                <div className='watch-video-wrp f-wrp'>
                                    <div className="row">
                                        <div className='col-lg-5 col-md-12 col-sm-12 col-xs-12'>
                                            <div className='watch-vid-box f-wrp'>
                                                <h3>{translateThis('Get a glimpse')}</h3>
                                                <p>{translateThis('Get a glimpse Text')}</p>
                                                <Link className='video-button provider-hub' onClick={() => window.open('https://youtu.be/ewRuuchr7Zo', '_blank')} >{SVGIcons.PlayRoundIcon()} {translateThis('Watch video')}</Link>
                                            </div>
                                        </div>
                                        <div className='col-lg-7 col-md-12 col-sm-12 col-xs-12'>
                                            <div className='watch-vid-img f-wrp'>
                                                <span><img src={AppIcons.digitalFacility} alt='digital facility' /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='gallery-tab-wrapper f-wrp'>
                                <div className='gallery-tab-header f-wrp'>
                                    <h1>{translateThis('Explore Our Gallery')}</h1>
                                    <p>{translateThis('Explore Our Gallery Text')}</p>
                                </div>

                                <div className='tab-sec-wrpapper f-wrp'>
                                    <TabLayout
                                        tabContents={tabContents}
                                        tabNames={tabNames}
                                    />
                                </div>

                            </div>


                        </div>

                        <div className='feature-main-wrapper f-wrp' id='feature'>
                            <div className='container'>
                                <div className='feature-detail-block f-wrp'>
                                    {FeatureList.map((element, key) => (
                                        <div className="each-feature-sec f-wrp" key={key}>
                                            <div className="feature-img-sec f-wrp">
                                                <span><img src={element.Image} alt={translateThis(element.title)} /></span>
                                            </div>

                                            <div className="feature-detail-sec f-wrp">
                                                <h1> {translateThis(element.title)}</h1>
                                                {element.content}
                                                {element.button ? <>{element.button}</> : null}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <Modal
                                className='efficiency-popup'
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="efficiency-popup"
                                aria-describedby="efficiency-popup"
                            >
                                <div className='efficiency-popup-con f-wrp'>
                                    <Button onClick={handleClose}>{SVGIcons.CrossIcon()}</Button>
                                    <span><img src={AppIcons.efficiencyPopup} alt='efficiency' /></span>
                                </div>

                            </Modal>

                            <Modal
                                className='efficiency-popup'
                                open={openReport}
                                onClose={handleCloseReport}
                                aria-labelledby="efficiency-popup"
                                aria-describedby="efficiency-popup"
                            >
                                <div className='efficiency-popup-con f-wrp'>
                                    <Button onClick={handleCloseReport}>{SVGIcons.CrossIcon()}</Button>
                                    <span><img src={AppIcons.reportsPopup} alt='Reports' /></span>
                                </div>
                            </Modal>
                        </div>

                        <div className='user-manual-wrp f-wrp' id='tutorials'>
                            <div className='container'>
                                <div className='manual-header f-wrp'>
                                    <h1>{translateThis('Tutorials')}</h1>
                                    <p>{translateThis('manualTitleText')}</p>
                                    <div className='video-btn-wrp'>
                                        <p>{SVGIcons.BooksIcon()} {translateThis('Watch all the videos in')}</p>
                                        <ul className='video-button-list'>
                                            <li>
                                                <Link className='video-button provider-hub' target='_blank' to={'https://youtube.com/playlist?list=PLZvAEaMuf9TZm0lZhZOBkAFca97wuZ9_D&feature=shared'}>{SVGIcons.PlayButtonIcon()} {translateThis('Provider Hub')}</Link>
                                            </li>
                                            <li>
                                                <Link className='video-button parent-hub' target='_blank' to={'https://youtube.com/playlist?list=PLZvAEaMuf9TYMmHw_CeZsPgr36fd-_F57&feature=shared'}>{SVGIcons.PlayButtonIcon()} {translateThis('Parent Hub')}</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className='accordian-wrapper f-wrp'>
                                    <div className="row">
                                        <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                                            <div className='accordian-sec-wrp f-wrp'>
                                                <p>{SVGIcons.BookIcon()} {translateThis('App Walkthrough')}</p>
                                                <div className='accordian-sec-box f-wrp'>

                                                    <Accordion className='each-accordian-sec' expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                                        <AccordionSummary
                                                            expandIcon={SVGIcons.ChevronDownIcon()}
                                                            aria-controls="panel1bh-content"
                                                            id="panel1bh-header"
                                                        >
                                                            <span>{SVGIcons.GrommetIcon()}</span>
                                                            <h4>{translateThis('Portal overview')} <p>{translateThis('accordianTextOne')}</p></h4>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <ul>
                                                                <li><Link onClick={() => window.open('https://youtu.be/Fg5K3tZmDZI', '_blank')} ><span className='parentIcon'>{SVGIcons.ParentIcon()}</span> {translateThis('Parent Portal')}</Link></li>
                                                                <li><Link onClick={() => window.open('https://youtu.be/ewRuuchr7Zo', '_blank')} ><span>{SVGIcons.HomeIcon()}</span> {translateThis('Provider Portal')}</Link></li>
                                                            </ul>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    <Accordion className='each-accordian-sec' expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                                        <AccordionSummary
                                                            expandIcon={SVGIcons.ChevronDownIcon()}
                                                            aria-controls="panel2bh-content"
                                                            id="panel2bh-header"
                                                        >
                                                            <span>{SVGIcons.UserAddIcon()}</span>
                                                            <h4>{translateThis('Enrollment')} <p>{translateThis('accordianTextTwo')}</p></h4>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <ul>
                                                                <li><Link onClick={() => window.open('https://www.youtube.com/playlist?list=PLZvAEaMuf9TYTFv0jy8LbbzMSACu4t-A1', '_blank')} ><span className='parentIcon'>{SVGIcons.ParentIcon()}</span> {translateThis('Parent Portal')}</Link></li>
                                                                <li><Link onClick={() => window.open('https://www.youtube.com/watch?v=IJhT-pyPP-I', '_blank')} ><span>{SVGIcons.HomeIcon()}</span> {translateThis('Provider Portal')}</Link></li>
                                                            </ul>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    <Accordion className='each-accordian-sec' expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                                        <AccordionSummary
                                                            expandIcon={SVGIcons.ChevronDownIcon()}
                                                            aria-controls="panel2bh-content"
                                                            id="panel2bh-header"
                                                        >
                                                            <span>{SVGIcons.CogIcon()}</span>
                                                            <h4>{translateThis('Features')} <p>{translateThis('accordianTextThree')}</p></h4>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <ul>
                                                                <li><Link onClick={() => window.open('https://youtu.be/lCzKvvmdGlQ', '_blank')} ><span className='PalmIcon'>{SVGIcons.PalmIcon()}</span> {translateThis('Attendance')}</Link></li>
                                                                <li><Link onClick={() => window.open('https://youtube.com/playlist?list=PLZvAEaMuf9TYmX6-rQPsojwdAZf04htdZ&feature=shared', '_blank')} ><span>{SVGIcons.FormsIcon()}</span> {translateThis('Forms')}</Link></li>
                                                                <li><Link onClick={() => window.open('https://youtube.com/playlist?list=PLZvAEaMuf9Ta4QA3Iw3b2ayLI4i-Kv9Sr&feature=shared', '_blank')} ><span>{SVGIcons.CatalogIcon()}</span> {translateThis('Communications')}</Link></li>
                                                                <li><Link onClick={() => window.open('https://youtu.be/R0npuCCHARg', '_blank')} ><span>{SVGIcons.AccidentIcon()}</span> {translateThis('Accidents')}</Link></li>
                                                                <li><Link onClick={() => window.open('#', '_blank')} ><span className='PaymentsIcon'>{SVGIcons.PaymentsIcon()}</span> {translateThis('Payments')}</Link></li>
                                                                <li><Link onClick={() => window.open('https://youtu.be/_jhzvgOWARI', '_blank')} ><span>{SVGIcons.BulletinIcon()}</span> {translateThis('Bulletin')}</Link></li>
                                                                <li><Link onClick={() => window.open('https://youtu.be/neYRZWg6RwQ', '_blank')} ><span>{SVGIcons.RuleIcon()}</span> {translateThis('Policies')}</Link></li>
                                                            </ul>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    <Accordion className='each-accordian-sec' expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                                        <AccordionSummary
                                                            expandIcon={SVGIcons.ChevronDownIcon()}
                                                            aria-controls="panel2bh-content"
                                                            id="panel2bh-header"
                                                        >
                                                            <span>{SVGIcons.EmployeeIcon()}</span>
                                                            <h4>{translateThis('Employee')} <p>{translateThis('accordianTextFour')}</p></h4>

                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <ul>
                                                                {/* <li><Link onClick={() => window.open('#', '_blank')} ><span>{SVGIcons.PlusIcon()}</span> {translateThis('Creation')}</Link></li> */}
                                                                <li><Link onClick={() => window.open('https://www.youtube.com/playlist?list=PLZvAEaMuf9TYPcBfd9DPIchpjG0SSHcny', '_blank')} ><span className='EmployeeIcon'>{SVGIcons.EmployeeIcon()}</span> {translateThis('Employee Portal')}</Link></li>
                                                                {/* <li><Link onClick={() => window.open('#', '_blank')} ><span className='PalmIcon'>{SVGIcons.PalmIcon()}</span> {translateThis('Attendance')}</Link></li> */}
                                                            </ul>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                                            <div className='video-wrp f-wrp'>
                                                <span>
                                                    <ReactPlayer width={'100%'} height={278} url='https://youtu.be/ewRuuchr7Zo' />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='plans-section-wrp f-wrp' id='pricing'>
                            <div className='container'>
                                <div className='plans-header-wrp f-wrp'>
                                    <h1>{translateThis('Plans')}</h1>
                                    <p>{translateThis('plansTitleText')}</p>
                                </div>
                                <div className='plans-content-wrp f-wrp'>
                                    <div className='row'>
                                        {PlanningList.map((element, key) => (
                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12" key={key}>
                                                <div className='each-plan-box f-wrp'>
                                                    <div className="plans-img-sec f-wrp">
                                                        {element.title ? <h1>{translateThis(element.title)}</h1> : null}
                                                        {element.Image ? <span><img src={element.Image} alt={translateThis(element.title)} /></span> : null}
                                                    </div>
                                                    <div className='plans-sub-wrp f-wrp'>
                                                        {element.subTitle ? <h3>{translateThis(element.subTitle)}</h3> : null}
                                                        {element.subTitle2 ? <h3><b>{translateThis(element.subTitle2)}</b> + </h3> : null}
                                                    </div>
                                                    <div className='plans-description-list f-wrp'>
                                                        {element.descriptionList}
                                                    </div>
                                                    <div className="plans-benefit-sec f-wrp">
                                                        {element.benefitTemplates ? <h4> {translateThis(element.benefitTemplates)}</h4> : null}
                                                        {element.languages ? <p><p><b>{element.languages}</b></p></p> : null}
                                                        {element.button ? <>{element.button}</> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='promotion-main-wrp f-wrp' id='promotions'>
                            <div className='container'>
                                <div className='promotion-con-box'>
                                    <div className='promotion-intro-sec'>
                                        <span><img src={AppIcons.promoMain} alt='PP promotions' /></span>
                                        <div className='intro-text'>
                                            <p><b>{translateThis('Have')}</b> {translateThis('less than 10 children')}<b> ?</b> <br />{translateThis('You can apply for')} </p>
                                            <h4>{translateThis('PPPromotions')}</h4>
                                        </div>
                                    </div>
                                    <div className='promotion-con-wrp'>
                                        <h2>{translateThis('PP Promotions')}</h2>
                                        <p>{translateThis('PP Promotions Text')}</p>

                                        <div className='quote-box'>
                                            <div className='each-quate-box'>
                                                <span><img src={AppIcons.promo2} alt='PP promotions' /></span>
                                                <p>{translateThis('quate Text1')}</p>
                                                <Link onClick={handleOpenPPForm}>{translateThis('Request quote')}</Link>
                                            </div>
                                            <div className='each-quate-box'>
                                                <span><img src={AppIcons.promo1} alt='PP promotions' /></span>
                                                <p>{translateThis('quate Text2')}</p>
                                                <Link onClick={handleOpenPPForm}>{translateThis('Request quote')}</Link>
                                            </div>
                                        </div>


                                    </div>
                                </div>


                            </div>
                        </div>

                        <div className='testimonial-main-wrp f-wrp' id='testimonial'>
                            <div className='container'>
                                <div className='testimonial-header-wrp f-wrp'>
                                    <h1>{translateThis('Customer Testimonials')}</h1>
                                </div>

                                <div className='testimonial-con-wrp f-wrp'>
                                    <Swiper
                                        modules={[Navigation, Autoplay, Pagination]}
                                        spaceBetween={10}
                                        slidesPerView={1}
                                        className='content-sec-mob'
                                        // navigation
                                        loop
                                        autoplay={{
                                            delay: 7000,
                                            disableOnInteraction: false,
                                        }}
                                        pagination={{
                                            dynamicBullets: false,
                                        }}
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 1,
                                                spaceBetween: 20,
                                            },
                                            640: {
                                                slidesPerView: 1,
                                                spaceBetween: 20,
                                            },
                                            991: {
                                                slidesPerView: 2,
                                                spaceBetween: 40,
                                            },
                                            1024: {
                                                slidesPerView: 3,
                                                spaceBetween: 50,
                                            },
                                        }}

                                    >
                                        {testimonialItems.map((element, key) => (
                                            <SwiperSlide key={key}>
                                                <div className='padding-class f-wrp'>
                                                    <div className="each-testimonial-items f-wrp">
                                                        {element.rating}
                                                        <span><img src={AppIcons.quote} alt='quats' /></span>
                                                        <p>{element.content}</p>
                                                        <div className='auth-sec'>
                                                            <span><img src={element.Image} alt="user" /></span>
                                                            <h2> {element.name}<p>{element.facility}</p></h2>
                                                        </div>

                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>

                                <div className='ppp-footer-box f-wrp'>
                                    <span><img src={AppIcons.logo2} alt='logo' /></span>
                                    <h1>{translateThis('I want')} <br /> {translateThis('my')} <br /> {translateThis('PP Plat')}</h1>
                                    <Button onClick={handleOpenPPForm} className='feature-btn'>{translateThis('I want my platform')}</Button>
                                </div>

                                <div className='copy-text'>
                                    <p>© 2023 MiHapp · Terms</p>
                                </div>
                            </div>
                        </div>
                        <Modal
                            className='ppContact-popup'
                            open={openPPForm}
                            onClose={handleClosePPForm}
                            aria-labelledby="ppContact-popup"
                            aria-describedby="ppContact-popup"
                        >
                            <div className='pp-contact-form f-wrp'>
                                <div className='pp-contact-head'>
                                    <p>🚀 {translateThis('I want my platform')} 📱 💻</p>
                                    <Button onClick={handleClosePPForm}>{SVGIcons.CrossIcon()}</Button>
                                </div>

                                <PPContactForm onSuccess={handleClosePPForm} defaultComment={`${translateThis("I want to build a platform for my childcare facility.")}`} />
                            </div>
                        </Modal>





                    </div>
                </>
            </AppLayout>
        </>
    )
}

export default PPPlatformHomePage;