import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { NavHashLink } from 'react-router-hash-link';
import Modal from '@mui/material/Modal';
import Marquee from "react-fast-marquee";
import S3ImageViewerWithFallback from '../../../components/awsS3ImageViewer/ImgViewerWithFallback';
import { getFileByKey } from '../../../services/api/aws';
import AppLayout from '../../../layouts/app/AppLayout';
import AppIcons from './images/icons';
import FriendlyFamilyServices from './Services/Services';
import './custom.scss';
import { translateThis } from '../../../helpers/language.helper';
import Gallery from './Gallery/gallery';
import Testimonial from './testimonial/testimonial';
import AppHeader from './header';
import AppFooter from './footer';
import Programs from './programs/programs';
import SVGIcons from './images/icons/svgIcons';
import Teachers from './teachers/teachers';
import Approach from './approach/approach';

import ContactForm from './contactForm/contactForm';
import { getCurrentFacilityURL } from '../../../helpers/helper.functions';

const TemplatesOne = ({ facilityData }) => {

    useEffect(() => {
        setTimeout(() => {
            document.body.classList.add('TemplateOne');
            document.body.classList.add(`${facilityData?.website?.colorPattern}`);

        }, 50);
        // eslint-disable-next-line
        getFacilityFavIcon()
        // eslint-disable-next-line
    }, []);
    const currentFacilityURL = getCurrentFacilityURL();

    const routineItems = [
        {
            icon: SVGIcons.FriendlyHours(),
            title: translateThis('routineHours'),
            content: `${facilityData?.website.workHours}`,
        },
        {
            icon: SVGIcons.FriendlyAge(),
            title: translateThis('routineAccepted ages'),
            content: `${facilityData?.website.workAcceptedAges}`,
        },
        {
            icon: SVGIcons.FriendlyDays(),
            title: translateThis('routineDays'),
            content: `${facilityData?.website.workDays}`,
        },
        {
            icon: SVGIcons.FriendlySubsidy(),
            title: translateThis('routineSubsidy programs'),
            content: `${facilityData?.website.workSubsidyPrograms}`,
        },

    ];

    const [openContactForm, setOpenContactForm] = useState(false);
    const [openTourForm, setOpenTourForm] = useState(false);
    const [favicon, setFavicon] = useState('');

    const handleOpenContactForm = () => {
        setOpenContactForm(true);
    };
    const handleCloseContactForm = () => {
        setOpenContactForm(false);
    };
    const handleOpenTourForm = () => {
        setOpenTourForm(true);
    };
    const handleCloseTourForm = () => {
        setOpenTourForm(false);
    };

    const getFacilityFavIcon = () => {
        if (facilityData?.website?.favIcon) {
            getFileByKey(facilityData?.website?.favIcon).then(({ data }) => {
                setFavicon(data?.imgURL || '')
            })
        }

    }

    const pageName = `${facilityData?.website?.websiteTitle}`;
    return (
        <AppLayout
            pageName={pageName}
            favicon={favicon}
            bodyClassName='TemplatesOne'
            customHeader={<AppHeader facilityData={facilityData} />}
            customFooter={<AppFooter />}
        >

            <div className="main-content-wrapper f-wrp">

                <div className='main-banner-wrp f-wrp'>
                    <div className='bannerBG'>
                        <div className='scroll-top f-wrp'>
                            <Marquee
                                autoFill
                                direction={"left"}
                                speed={70}
                            >
                                <span className='scrollBG'><img src={AppIcons.scrollTop} alt='banner' /></span>
                            </Marquee>
                        </div>

                        <span className='pcBG'><S3ImageViewerWithFallback key={`file-${facilityData?.website?.bannerImage}`} fileKey={facilityData?.website?.bannerImage} /></span>
                        <span className='mobBG'><S3ImageViewerWithFallback key={`file-${facilityData?.website?.bannerImage}`} fileKey={facilityData?.website?.bannerImage} /></span>
                        <div className='scroll-bottom f-wrp'>
                            <Marquee
                                autoFill
                                direction={"left"}
                                speed={70}
                            >
                                <span className='scrollBG'><img src={AppIcons.scrollBottom} alt='banner' /></span>
                            </Marquee>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='banner-align f-wrp'>
                            <div className='banner-con-sec f-wrp'>
                                <div className='banner-con-box f-wrp'>
                                    <div className='banner-con'>
                                        <h1>{facilityData?.website?.bannerTitle}</h1>
                                        <p>{facilityData?.website?.bannerShortDescription}</p>
                                    </div>
                                </div>
                                <div className='banner-btn-sec f-wrp'>
                                    <Button onClick={() => {
                                        window.location.href = `/${currentFacilityURL}/my-portal`
                                    }} className='sqr-btn'>{facilityData?.website?.bannerButtonName1}
                                    </Button>
                                    <NavHashLink smooth to={'#our-program'} >
                                        <Button className='sqr-btn child2'>
                                            {facilityData?.website?.bannerButtonName2}
                                            {/* {translateThis('Our program')} */}
                                        </Button>
                                    </NavHashLink>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='service-slider-sec f-wrp'>
                    <div className='service-sec-header f-wrp'>
                        <div className='container'>
                            <h1>{facilityData?.website?.serviceSectionTitle}</h1>
                            <p>{facilityData?.website?.serviceSectionShortDescription}</p>
                        </div>
                    </div>
                    <FriendlyFamilyServices facilityData={facilityData?.website?.services || []} />
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='about-section-wrp f-wrp'>
                    <div className='container'>
                        <div className='about-grid-wrp f-wrp'>
                            <div className='about-con-sec'>
                                <h1>{facilityData?.website?.workBlockTitle}</h1>
                                <p>{facilityData?.website?.workBlockShortDescription}</p>
                                <p>{facilityData?.website?.workBlockDescription}</p>
                            </div>
                            <div className='about-img-sec'>
                                <div className='about-main-img'>
                                    <span><S3ImageViewerWithFallback key={`file-${facilityData?.website?.workImageOne}`} fileKey={facilityData?.website?.workImageOne} /></span>
                                </div>

                                <div className='about-img-subset'>
                                    <span><S3ImageViewerWithFallback key={`file-${facilityData?.website?.workImageTwo}`} fileKey={facilityData?.website?.workImageTwo} /></span>
                                    <span><S3ImageViewerWithFallback key={`file-${facilityData?.website?.workImageThree}`} fileKey={facilityData?.website?.workImageThree} /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='info-wrapper-section f-wrp'>
                    <div className='routine-box f-wrp'>
                        <ul>
                            {routineItems.map((element, key) => (
                                <>
                                    {element.content ? <li key={key}>
                                        <div className='each-routine-box f-wrp'>
                                            <div className='icon-sec'>
                                                <span>{element.icon}</span>
                                            </div>
                                            <div className='content-sec'>
                                                <h3>{element.title}</h3>
                                                <p>{element.content}</p>

                                            </div>
                                        </div>
                                    </li> : null}
                                </>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='programs-slider-sec f-wrp' id='our-program'>
                    <div className='container'>
                        <div className='programs-sec-header f-wrp'>
                            <h1>{facilityData?.website?.programBlockTitle}</h1>
                            <p>{facilityData?.website?.programBlockShortDescription}</p>
                        </div>
                        <div className='gap f-wrp' style={{ paddingTop: '75px' }} />
                        <Programs programsArr={facilityData?.website?.programs || []} />
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='gallery-section-wrp f-wrp'>
                    <div className='container-fluid'>
                        <div className='gallery-sec-header f-wrp'>
                            <h1>{facilityData?.website?.galleryBlockTitle}</h1>
                            <p>{facilityData?.website?.galleryBlockShortDescription}</p>
                            <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                        </div>
                        <Gallery facilityData={facilityData?.website?.galleryImages || []} />
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />


                <div className='install-blk-wrp f-wrp'>
                    <div className='container'>
                        <div className='install-sec-blk f-wrp'>
                            <div className='install-img'>
                                <span><S3ImageViewerWithFallback key={`file-${facilityData?.website?.installationImage}`} fileKey={facilityData?.website?.installationImage} /></span>
                            </div>
                            <div className='install-con'>
                                <div className='f-wrp'>
                                    <h1>{facilityData?.website?.installationBlockTitle}</h1>
                                    <p>{facilityData?.website?.installationBlockDescription}</p>

                                    {/* <NavHashLink smooth to={'#getInTouch'} > */}
                                    <Button className='sqr-btn' onClick={handleOpenTourForm} >{facilityData?.website?.installationBlockButtonTitle}</Button>
                                    {/* </NavHashLink> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='approach-blk-wrp f-wrp' id='tailored-approach'>
                    <div className='container'>
                        <div className='approach-sec-header f-wrp'>
                            <h1>{facilityData?.website?.extrasBlockTitle}</h1>
                            <p>{facilityData?.website?.extrasBlockShortDescription}</p>
                            <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                        </div>
                        <Approach facilityData={facilityData?.website?.extraItems || []} />
                    </div>
                </div>
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='info-blk-wrp f-wrp' id='getInTouch'>
                    <div className='container'>
                        <div className='info-sec-blk f-wrp'>
                            <div className='info-con'>
                                <div className='f-wrp'>
                                    <h1>{facilityData?.website?.contactUsBlockTitle}</h1>
                                    <p>{facilityData?.website?.contactUsBlockShortDescription}</p>
                                    <ul>
                                        {facilityData?.website?.contactUsBlockLicenseNumber && <li><span>{SVGIcons.LicenseIcon()} </span>{facilityData?.website?.contactUsBlockLicenseNumber}</li>}
                                        {facilityData?.website?.contactUsBlockAddress && <li><span>{SVGIcons.PinIcon()} </span>{facilityData?.website?.contactUsBlockAddress}</li>}
                                        {facilityData?.website?.contactUsBlockEmail && <li><a href={`mailto:${facilityData?.website?.contactUsBlockEmail}`}><span>{SVGIcons.MailIcon()} </span>{facilityData?.website?.contactUsBlockEmail}</a></li>}
                                        {facilityData?.website?.contactUsBlockPhone && <li><span>{SVGIcons.PhoneIcon()} </span><a href={`tel:${facilityData?.website?.contactUsBlockPhone}`}>{facilityData?.website?.contactUsBlockPhone}</a></li>}
                                    </ul>

                                    <ul className='social-media-list'>
                                        {facilityData?.website?.contactUsBlockAddress && <li>
                                            <Button onClick={() => {
                                                window.open(`${facilityData?.website?.contactUsBlockFbUrl}`, '_blank')
                                            }} className='social-btn'><img src={AppIcons.fb} alt='Facebook' /></Button>
                                        </li>}
                                        {facilityData?.website?.contactUsBlockWhatsappUrl && <li>
                                            <Button onClick={() => {
                                                window.open(`${facilityData?.website?.contactUsBlockWhatsappUrl}`, '_blank')
                                            }} className='social-btn'><img src={AppIcons.whatsapp} alt='Whatsapp' /></Button>
                                        </li>}
                                        {facilityData?.website?.contactUsBlockInstagramUrl && <li>
                                            <Button onClick={() => {
                                                window.open(`${facilityData?.website?.contactUsBlockInstagramUrl}`, '_blank')
                                            }} className='social-btn'><img src={AppIcons.insta} alt='Instagram' /></Button>
                                        </li>}
                                    </ul>
                                    <Button className='sqr-btn' onClick={handleOpenContactForm} >{translateThis('contactUs')}</Button>

                                </div>
                            </div>
                            <div className='info-img'>
                                <span><S3ImageViewerWithFallback key={`file-${facilityData?.website?.contactUsImage}`} fileKey={facilityData?.website?.contactUsImage} /></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='teachers-blk-wrp f-wrp'>
                    <div className='container'>
                        <div className='teachers-sec-header f-wrp'>
                            <h1>{facilityData?.website?.ourTeachersBlockTitle}</h1>
                            <p>{facilityData?.website?.ourTeachersBlockShortDescription}</p>
                            <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                        </div>
                        <Teachers facilityData={facilityData?.website?.teachers || []} />
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

                <div className='payment-blk-wrp f-wrp'>
                    <div className='container'>
                        <div className='payment-sec-blk f-wrp'>
                            <div className='payment-img'>
                                <span><S3ImageViewerWithFallback key={`file-${facilityData?.website?.paymentSideImage}`} fileKey={facilityData?.website?.paymentSideImage} /></span>
                            </div>
                            <div className='payment-con'>
                                <div className='f-wrp'>
                                    <h1>{facilityData?.website?.ourPaymentBlockTitle}</h1>
                                    <p>{facilityData?.website?.ourPaymentBlockDescription} </p>
                                    <Button onClick={() => {
                                        window.location.href = `/${currentFacilityURL}/my-portal`
                                    }} className='sqr-btn'>{facilityData?.website?.ourPaymentBlockButtonTitle}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />


                <div className='testimonial-blk-wrp f-wrp'>
                    <div className='container'>
                        <div className='testimonial-sec-header f-wrp'>
                            <h1>{facilityData?.website?.ourTestimonialBlockTitle}</h1>
                            <p>{facilityData?.website?.ourTestimonialBlockShortDescription}</p>
                            <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                        </div>
                        <Testimonial facilityData={facilityData?.website?.testimonials || []} />
                    </div>
                </div>

                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />
                <div className='gap f-wrp' style={{ paddingTop: '50px' }} />

            </div>

            <Modal
                className='ppContact-popup'
                open={openContactForm}
                onClose={handleCloseContactForm}
                aria-labelledby="ppContact-popup"
                aria-describedby="ppContact-popup"
            >
                <div className='pp-contact-form f-wrp'>
                    <div className='pp-contact-head'>
                        <p>{translateThis('Contact us')} 💬</p>
                        <Button onClick={handleCloseContactForm}>{SVGIcons.CrossIcon()}</Button>
                    </div>

                    <ContactForm onSuccess={handleCloseContactForm} />
                </div>
            </Modal>

            <Modal
                className='ppContact-popup'
                open={openTourForm}
                onClose={handleCloseTourForm}
                aria-labelledby="ppContact-popup"
                aria-describedby="ppContact-popup"
            >
                <div className='pp-contact-form f-wrp'>
                    <div className='pp-contact-head'>
                        <p>{translateThis('Our installation virtual tour')} 🎥</p>
                        <Button onClick={handleCloseTourForm}>{SVGIcons.CrossIcon()}</Button>
                    </div>

                    <ContactForm onSuccess={handleCloseTourForm} defaultComment='Hello, I’m interested in the virtual tour.' />
                </div>
            </Modal>


        </AppLayout>
    )
}
TemplatesOne.propTypes = {
    facilityData: PropTypes.any
}
export default TemplatesOne;