import React from 'react';
 import {Editor,  EditorState,  convertFromRaw} from 'draft-js';
import { PropTypes } from 'prop-types';

 const RichTextViewer = (props) => {
  const {richText = ''} = props;
  function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
    const itemText= (isJsonString(richText)) ?  EditorState.createWithContent(convertFromRaw(JSON.parse(richText))): '';
   return (
      <div className="readonly-editor">
        {itemText ? <Editor editorState={itemText} readOnly /> : richText} 
      </div>
   );
 }
RichTextViewer.propTypes = {
  richText: PropTypes.string
}
 export default RichTextViewer;