import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import './header.scss';
import BurgerMenu from './nav-section/burger';
import { switchLanguage } from '../../../helpers/language.helper';
import { appConfigAtomState } from '../../../state/reducerAtoms/app.atom';
import useGTM from '../../../components/googleTagManager';

export default function EvolveAppHeader() {
    // Google Tag Manager code for friendlyFamily
    useGTM('GTM-PRXBDM54');

    const appStateAs = useRecoilValue(appConfigAtomState);
    const [appState, setAppState] = useState({
        selectedLang: appStateAs?.appStateAs || ''
    })
    useEffect(() => {
        setAppState(appStateAs);
    }, [appStateAs]);

    return (
        <>
            <div className='main-head-wrp EvolveAppHeader f-wrp'>
                <div className='container-fluid'>
                    <div className='burger-prof-sec'>
                        <div className='language-select-slide'>
                            <button type='button' className={`pln-btn ${appState.selectedLang === 'es' && 'active'}`} onClick={() => switchLanguage('es')}>Español</button>
                            <button type='button' className={`pln-btn ${appState.selectedLang === 'en' && 'active'}`} onClick={() => switchLanguage('en')}>English</button>
                        </div>
                        <BurgerMenu />
                    </div>
                </div>
            </div>
        </>
    )

}