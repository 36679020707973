import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Box, Grid } from '@mui/material';
import dayjs from 'dayjs';


import SVGIcons from '../../../assets/images/icons/svgIcons';
import './index.scss';
import ProviderPortalHeader from '../components/header';
import AppIcons from '../../../assets/images/icons';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';

import { MONTH_YEAR_TEXT_FORMAT, TIME_12HR_FORMAT, TODAYS_DATE_EXTENDED_FORMAT } from '../../../constants';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
const ProviderDashboard = () => {

    const apiCaller = new ApiCaller('dashboard');
    const [data, setData] = useState({
        cardData: [],
        dashboard: {}
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isChildrenAttendanceLoading, setIsChildrenAttendanceLoading] = useState(true);
    const [childrenAttendanceArr, setChildrenAttendanceArr] = useState([]);
    const [isInboxMetricLoading, setIsInboxMetricLoading] = useState(true);
    const [inboxMetric, setInboxMetric] = useState({});

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr();        
        return () => {
            setData({
                cardData: [],
                dashboard: {}
            })
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getData('')
            .then((data) => formatData(data))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => {
                getChildrenAttendanceList();
                getDailyLogMetric();
                setIsLoading(false);

            });
    }

    // get users from api
    const getChildrenAttendanceList = () => {
        setIsChildrenAttendanceLoading(true);
        apiCaller.getData('children-attendance')
            .then((data) => setChildrenAttendanceArr(data))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsChildrenAttendanceLoading(false));
    }
    // get getDailyLogMetric from api
    const getDailyLogMetric = () => {
        apiCaller.getData('daily-log-metrics')
            .then((metric) => {
                const cards = data.cardData;
                const dailyLogMetric = {
                    headIcon: SVGIcons.CatalogIcon(),
                    title: 'Daily logs',
                    infoLine1: <><p>Pending signature <b>{metric?.totalPendingSignature}</b></p></>,
                    infoLine2: <><p>Daily submissions <b>{metric?.submissionsToday}</b></p></>,
                    infoLine3: <><p>Total submissions <b>{metric?.totalSubmissions}</b></p></>,
                    link: ''
                }
                cards.push(dailyLogMetric);
                setData((prv) => ({ ...prv, cardData: [...cards] }));
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => getFormsMetric())
    }

    // get setIsInboxMetricLoading from api
    const getInboxMetric = () => {
        setIsInboxMetricLoading(true)
        apiCaller.getData('inbox-metrics')
            .then((metric) => {
                setInboxMetric(metric)
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsInboxMetricLoading(false))
    }

    // get getFormsMetric from api
    const getFormsMetric = () => {
        apiCaller.getData('forms-metrics')
            .then((metric) => {
                const cards = data.cardData;
                const formsMetricArr = [{
                    headIcon: SVGIcons.FormsIcon(),
                    title: 'Forms',
                    infoLine1: <><p>Pending signature <b>{metric?.totalPendingSignature}</b></p></>,
                    infoLine2: <><p>Submissions this month<b>{metric?.submissionsThisMonth}</b></p></>,
                    infoLine3: <><p>Total submissions <b>{metric?.totalSubmissions}</b></p></>,
                    link: ''
                },
                {
                    headIcon: SVGIcons.AccidentIcon(),
                    title: 'Accidents',
                    infoLine1: <><p>Current month <b>{metric?.accidentCurrentMonth}</b></p></>,
                    infoLine2: <><p>Current year <b>{metric?.accidentCurrentYear}</b></p></>,
                    link: ''
                }
                ]
                const allCards = cards.concat(formsMetricArr);
                const uniqueData = allCards.filter((item, index, self) => 
                    index === self.findIndex((t) => (t.title === item.title))
                  );
                setData((prv) => ({ ...prv.dashboard, cardData: [...uniqueData] }));
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => getInboxMetric())
    }

    const formatData = (dataObj) => {        
        setData((prv)=>({...prv, dashboard: dataObj, ...dataObj}))

    }

    // const options = {
    //     responsive: true,
    //     plugins: {
    //         legend: {
    //             // position: 'top' as const,
    //         },
    //         title: {
    //             display: false,
    //             text: 'Chart.js Line Chart',
    //         },
    //     },
    // };

    // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    // const dataAs = {
    //     labels,
    //     datasets: [
    //         {
    //             // label: 'Dataset 1',
    //             data: 100,
    //             borderColor: 'rgb(255, 99, 132)',
    //             backgroundColor: 'rgba(255, 99, 132, 0.5)',
    //         },
    //         {
    //             // label: 'Dataset 2',
    //             data: 1000,
    //             borderColor: 'rgb(53, 162, 235)',
    //             backgroundColor: 'rgba(53, 162, 235, 0.5)',
    //         },
    //     ],
    // };


    return (

        <>
            <ProviderPortalHeader header='Dashboard' />
            {<Box component="div" className='dashboard-main-wrp f-wrp' sx={{ flexGrow: 1, p: 3, }}>
                <Grid container spacing={2}>
                    <Grid key={data.cardData.length} item xs={12} sm={12} md={12} lg={8}>
                        <Grid container spacing={2}>
                            {data?.cardData.map((element, key) => (
                                // eslint-disable-next-line react/jsx-key
                                <Grid item xs={12} sm={12} md={3} lg={3} key={key}>
                                    <div className={`${element.title} each-board-items f-wrp `}>
                                        <div className='card-header'>
                                            <span className={`${element.title}`}>{element.headIcon}</span>
                                            <h4>{element.title}</h4>
                                        </div>
                                        <div className='card-body'>
                                            {element.infoLine1}
                                            {element.infoLine2}
                                            {element.infoLine3 || ''}
                                        </div>
                                        {/* <Divider />
                                        <div className='card-foot'>
                                            <Button className='pln-btn' onClick={() => console.log('clicked')} >Read more {SVGIcons.ArrowTrIcon()}</Button>
                                        </div> */}
                                    </div>
                                </Grid>
                            ))}
                            <Grid item xs={12} sm={12} md={2} lg={3}>
                                {!isInboxMetricLoading ? <div className='inbox-card f-wrp'>
                                    <span>{SVGIcons.InboxIcon()}</span>
                                    <h4>Inbox <b>{inboxMetric?.totalUnread}</b></h4>
                                    <p>Unread Messages</p>
                                    <p><b>{inboxMetric?.totalMessages}</b> Total Messages</p>
                                </div> : <span>Loading...</span>}
                            </Grid>
                            <Grid item xs={12} sm={12} md={9} lg={9}>
                                {!isLoading ? <div className='graph-wrp-sec'>
                                    <span className='graph-img'><img src={AppIcons.graphBG} alt='img' /></span>
                                    <div className='graph-box-card f-wrp'>
                                        <div className='card-header'>
                                            <span>{SVGIcons.PaymentsIcon()}</span>
                                            <h4>Payments collected</h4>
                                        </div>
                                        <div className='price-sec-container'>
                                            <div className='each-price-sec'>
                                                <h4>This month</h4>
                                                <p>{dayjs().format(MONTH_YEAR_TEXT_FORMAT)}</p>
                                                <h2>${data?.totalPaymentsOfMonth || 0}</h2>
                                            </div>
                                            <div className='each-price-sec'>
                                                <h4>This year</h4>
                                                <p>{dayjs().year()}</p>
                                                <h2>${data?.totalPaymentsOfYear || 0}</h2>
                                            </div>
                                            <div className='each-price-sec'>
                                                <h4>Historical</h4>
                                                <p>{dayjs().subtract(1, 'year').format(MONTH_YEAR_TEXT_FORMAT)} to {dayjs().format(MONTH_YEAR_TEXT_FORMAT)}</p>
                                                <h2>${data?.totalPaymentsOfAllTime || 0}</h2>
                                            </div>
                                        </div>
                                        <div className='graph-box f-wrp'>
                                            {/* <Line options={options} data={data} /> */}

                                        </div>
                                    </div>
                                </div> : <span>Loading...</span>}
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                {!isLoading ? <div className="each-board-items activeChildBox f-wrp">
                                    <div className='card-header'>
                                        <span>{SVGIcons.ChildIcon()}</span>
                                        <h4>Children</h4>
                                    </div>
                                    <div className='card-body'>
                                        <p>Active children <b>{data?.activeChildren}</b></p>
                                        <p>Inactive children <b>{data?.inActiveChildren}</b></p>
                                    </div>
                                    <div className='card-header'>
                                        <span>{SVGIcons.ParentIcon()}</span>
                                        <h4>Parents</h4>
                                    </div>
                                    <div className='card-body'>
                                        <p>Active parent <b>{data?.activeParent}</b></p>
                                        <p>Inactive parent <b>{data?.inActiveParent}</b></p>
                                    </div>
                                </div> : <span>Loading...</span>}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <div className='date-indicator-sec each-board-items f-wrp'>
                            <h4>{dayjs().format(TODAYS_DATE_EXTENDED_FORMAT)}</h4>
                            <span>{SVGIcons.ProfileIcon()}</span>
                        </div>
                        <div className='attendance-box-wrp each-board-items f-wrp'>
                            <div className='card-header'>
                                <span>{SVGIcons.ClockIcon()}</span>
                                <h4>Attendance</h4>
                            </div>
                            <div className='attendanceList-sec f-wrp'>
                                {(!isChildrenAttendanceLoading && childrenAttendanceArr.length > 0) ? <ul>
                                    {childrenAttendanceArr.map((child, key) => (
                                        <li key={key}>
                                            <span>{SVGIcons.ProfileIcon()}</span>
                                            <h4>{child?.childId?.firstName || ''} {child?.childId?.lastName || ''}</h4>
                                            <p>{`${dayjs(child.checkIn).format(TIME_12HR_FORMAT)} - ${child.checkOut ? dayjs(child.checkOut).format(TIME_12HR_FORMAT) : ''}`}</p>
                                        </li>
                                    ))}
                                </ul> : <span>{isLoading ? 'Loading...' : 'No children have checked in today yet'}</span>}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Box>}
        </>
    );
}

ProviderDashboard.propTypes = {};

export default ProviderDashboard;