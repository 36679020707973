export const availableCountries = [
  {
    label: "Australia +61",
    id: 'Australia_+61',
    value: 'Australia$+61',
  },
  {
    label: "Brazil +55",
    id: 'Brazil_+55',
    value: 'Brazil$+55',
  },
  {
    label: "Canada +1",
    id: 'Canada_+1',
    value: 'Canada$+1',
  },
  {
    label: "China +86",
    id: 'China_+86',
    value: 'China$+86',
  },
  {
    label: "France +33",
    id: 'France_+33',
    value: 'France$+33',
  },
  {
    label: "Germany +49",
    id: 'Germany_+49',
    value: 'Germany$+49',
  },
  {
    label: "India +91",
    id: 'India_+91',
    value: 'India$+91',
  },
  {
    label: "Italy +39",
    id: 'Italy_+39',
    value: 'Italy$+39',
  },
  {
    label: "Japan +81",
    id: 'Japan_+81',
    value: 'Japan$+81',
  },
  {
    label: "Mexico +52",
    id: 'Mexico_+52',
    value: 'Mexico$+52',
  },
  {
    label: "Russia +7",
    id: 'Russia_+7',
    value: 'Russia$+7',
  },
  {
    label: "South Africa +27",
    id: 'South_Africa_+27',
    value: 'South Africa$+27',
  },
  {
    label: "South Korea +82",
    id: 'South_Korea_+82',
    value: 'South Korea$+82',
  },
  {
    label: "Spain +34",
    id: 'Spain_+34',
    value: 'Spain$+34',
  },
  {
    label: "United Kingdom +44",
    id: 'United_Kingdom_+44',
    value: 'United Kingdom$+44',
  },
  {
    label: "United States +1",
    id: 'United_States_+1',
    value: 'United States$+1',
  },
];