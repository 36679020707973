/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SVGIcons from '../images/icons/svgIcons';

import './header.scss';
import BurgerMenu from '../../../../components/nav-section/burger';
import AppPopOverMenu from '../../../../components/appPopOverMenu/index';
import { switchLanguage } from '../../../../helpers/language.helper';
import S3ImageViewerWithFallback from '../../../../components/awsS3ImageViewer/ImgViewerWithFallback';
import { getCurrentFacilityURL } from '../../../../helpers/helper.functions';

const AppHeader = ({ facilityData }) => {

    const currentFacilityURL = getCurrentFacilityURL();


    return (
        <>
            <div className='main-head-wrp TemplatesOneHeader f-wrp'>
                <div className='container'>
                    <div className='main-header f-wrp'>
                        <Link to={`/${currentFacilityURL}`} className='main-logo'>
                            <S3ImageViewerWithFallback key={`file-${facilityData?.facilityLogo?.fileKey}`} fileKey={facilityData?.facilityLogo?.fileKey} />
                        </Link>

                        <div className='burger-prof-sec'>
                            <div className='language-box'>
                                <AppPopOverMenu
                                    icon={SVGIcons.GlobalIcon()}
                                    options={[
                                        {
                                            label: 'English',
                                            onClick: () => switchLanguage('en')
                                        },
                                        {
                                            label: 'Spanish',
                                            onClick: () => switchLanguage('es')
                                        },
                                    ]}
                                />
                            </div>
                            <div className='f-wrp'>
                                <div className='menu-box'>
                                    <BurgerMenu />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
AppHeader.propTypes = {
    facilityData: PropTypes.any
}
export default AppHeader;