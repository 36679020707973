import React, { useState, useEffect } from 'react';

// Components

import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import AppPopOverMenu from '../../../components/appPopOverMenu';
import ConfirmDialog from '../../../components/dialogs/confirm.dialog';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import { translateThis } from '../../../helpers/language.helper';
import AppIcons from '../../../assets/images/icons';

const EmployeeProfileList = () => {
    
    const apiCaller = new ApiCaller('users/employees');
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => setDataArr(data?.results || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * status update API
   * @param {string} id 
   * @param {string} status 
   */
    const toggleActiveStatus = (id, status) => {
        status = status === "active" ? "inactive" : "active";
        const statusMsg = status === "active" ? "accepted" : "blocked";
        if (id) {
            apiCaller.updateData(id, { status })
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected profile has been ${statusMsg} successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    getDataArr();
                })
        }

    }

    /**
   * User delete
   */
    const deleteData = () => {
        if (selectedId) {
            apiCaller.updateData(selectedId, { isDeleted: true })
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected employee has been deleted successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getDataArr();
                })
        } else {
            setOpenDeleteDialog(false);
        }

    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'fullName',
            headerName: 'Name',
            minWidth: 150,
            flex: 2,
            valueGetter: (params) =>
                `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 2,
            minWidth: 200,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            minWidth: 90,
            sortable: false,
            renderCell: (params) => {
                const { status } = params.row;
                return <>{(status === 'active' && <span className='tab-status'>{SVGIcons.StatusActiveIcon()}  </span>) ||
                    (status === 'inactive' && <span className='tab-status'>{SVGIcons.StatusInactiveIcon()} </span>)}</>
            }
        },
        {
            field: 'id',
            headerName: 'Actions',
            flex: 0.5,
            minWidth: 90,
            sortable: false,
            renderCell: (params) => {
                const { id, status } = params.row;
                return <AppPopOverMenu options={[
                    
                    {
                        label: status === "active" ? "Pause" : "Accept",
                        onClick: () => {
                            toggleActiveStatus(id, status)
                        }
                    },
                    {
                        label: "Delete",
                        onClick: () => {
                            setSelectedId(id);
                            setOpenDeleteDialog(true);
                        }
                    }
                ]} />
            }
        },

    ]


    return (
        <div className='employees-main-list f-wrp'>
            <div className='employees-con-wrp f-wrp'>
                <div style={{ width: '100%' }} className="table-sec-wrp f-wrp">
                    <div className='panel-table-sec provider-table PC-Table f-wrp'>
                        <AppTable
                            columns={tableColumns}
                            isLoading={isLoading}
                            data={dataArr}
                        />
                        <div className='mob-table-wrp f-wrp'>
                            {dataArr.length > 0 ? <ul>
                                {dataArr.map((element) => (
                                    <li key={element.id}>
                                        <div className='each-sec-box'>
                                            {element.image && element.image?.tempURL ? <div className='each-img-blk' key={`image-${element.image.fileKey}`}>
                                                <div className='profile-img-sec'>
                                                    <span><img src={element.image.tempURL} alt={element.image.fileKey} /></span>
                                                </div>
                                            </div> : <div className='each-img-blk'>
                                                <div className='profile-img-sec'>
                                                    <span><img src={AppIcons.placeholder} alt='placeholder' /></span>
                                                </div>
                                            </div>}
                                        </div>
                                        <div className='each-sec-box'>
                                            <h4>{element.firstName} {element.lastName}</h4>
                                            <span>{element.email}</span>
                                        </div>
                                        <div className='each-sec-box'>
                                            <ul className='btn-wrp'>
                                                <li><div className='status-with-btn'>
                                                    {(element.status === 'active' && <span className='tab-status'>{SVGIcons.StatusActiveIcon()}  </span>) ||
                                                        (element.status === 'blocked' && <span className='tab-status'>{SVGIcons.StatusBlockedIcon()}  </span>) ||
                                                        (element.status === 'inactive' && <span className='tab-status'>{SVGIcons.StatusInactiveIcon()} </span>)}
                                                    <AppPopOverMenu
                                                        icon={SVGIcons.StatusMobArrow()}
                                                        options={[
                                                            {
                                                                label: element.status === "active" ? "Pause" : "Accept",
                                                                onClick: () => {
                                                                    toggleActiveStatus(element.id, element.status)
                                                                }
                                                            },
                                                        ]} />
                                                </div></li>
                                                <li><button className='pln-btn ForwardIcon' type='button' onClick={() => { setSelectedId(element.id); setOpenDeleteDialog(true) }}><SVGIcons.DeleteIcon /></button></li>
                                            </ul>
                                        </div>
                                    </li>
                                ))}
                            </ul> : <div className='empty-placeholder f-wrp'>
                                <div className='placeholder-con'>
                                    <img src={AppIcons.parentPlaceholder} alt='empty' />
                                    <h4>{translateThis('No statistics')}</h4>
                                    <p>{translateThis('There are no information loaded in the system')}</p>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    {openDeleteDialog && <ConfirmDialog
                        isOpen={openDeleteDialog}
                        onClose={() => {
                            setOpenDeleteDialog(false);
                            setSelectedId('');
                        }}
                        title={`Delete profile`}
                        className={'delete-popup'}
                        description={`Are you sure you want to delete this profile?\nThis action is permanent and cannot be undone.`}
                        okayButtonText={'Delete profile'}
                        onConfirm={deleteData}
                    />}
                </div>
            </div>
        </div>
    )
}


EmployeeProfileList.propTypes = {
}
export default EmployeeProfileList;