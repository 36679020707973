import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SVGIcons from '../images/icons/svgIcons';
import './testimonial.scss';
import { translateThis } from "../../../../helpers/language.helper";
import S3ImageViewerWithFallback from "../../../../components/awsS3ImageViewer/ImgViewerWithFallback";

function Testimonial({ facilityData }) {

  // eslint-disable-next-line react/prop-types
  const CustomPrevButton = ({ onClick }) => (
    <button type='button' className="swiper-button-prev" onClick={onClick}>
      <SVGIcons.SlideLeft />
    </button>
  );
  // eslint-disable-next-line react/prop-types
  const CustomNextButton = ({ onClick }) => (
    <button type='button' className="swiper-button-next" onClick={onClick}>
      <SVGIcons.SlideRight />
    </button>
  );
  const swiperRef = useRef(null);


  const ShortName = ({ name }) => {
    const getShortName = (name) => {
      const words = name.split(' ');
      return words.slice(0, 2).map(word => word.charAt(0).toUpperCase()).join('').slice(0, 2);
    };
    const shortName = getShortName(name);
    return (
        <>{shortName}</>
    );
  };

  

  return (
    <>
      <div className="TemplatesOne-testimonial-sec-wrp">
        <div className="f-wrp">
          <Swiper
            onSwiper={(swiper) => { swiperRef.current = swiper; }}
            modules={[Navigation, Autoplay]}
            spaceBetween={10}
            slidesPerView={2}
            className='content-sec-mob'
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            loop
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}

            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1275: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
            }}

          >
            {facilityData.map((element, key) => (
              <SwiperSlide key={key}>
                <div className="each-testimonial-items f-wrp">
                  <p>{translateThis(element.description)}</p>
                  <h2>
                    {element.image ? <span className='authImg'>{<S3ImageViewerWithFallback key={`file-${element?.image.fileKey}`} fileKey={element?.image.fileKey} />}</span> : <span className='shortName'><ShortName name={element.title}  /></span>}
                    {translateThis(element.title)} <span className='quats'><SVGIcons.QuatsIcon /></span></h2>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <CustomPrevButton onClick={() => swiperRef.current.slidePrev()} />
          <CustomNextButton onClick={() => swiperRef.current.slideNext()} />
        </div>
      </div>
    </>
  );
}
Testimonial.propTypes = {
  facilityData: PropTypes.any
}
export default Testimonial;