/* eslint-disable global-require */
const AppIcons = {
  sampleIcon: require('./sample.ico'),
  logo: require('../Logo.png'),
  bannerLogo: require('../animatedBanner/Logo.png'),
  loginBG: require('../login.jpg'),
  registerBG: require('../register.jpg'),

  calenderIcon: require('./calenderIcon.png'),
  carbonRuleIcon: require('./carbonRuleIcon.png'),
  creditCardIcon: require('./creditCardIcon.png'),
  fileCheckIcon: require('./fileCheckIcon.png'),
  Kcolorful: require('./Kcolorful.png'),
  logoutIcon: require('./logoutIcon.png'),
  phoneIcon: require('./phoneIcon.png'),
  savingsIcon: require('./savingsIcon.png'),
  twotoneFaceIcon: require('./twotoneFaceIcon.png'),

  kcolorfulBanner: require('../kcolorfulBanner.png'),
  childern: require('../banner/childern.png'),
  clouds: require('../banner/clouds.png'),
  dinoGroup: require('../banner/dinoGroup.png'),

  Playground: require('./Playground.png'),
  apple: require('./apple.png'),
  brain: require('./brain.png'),
  car: require('./car.png'),
  child: require('./child.png'),
  events: require('./events.png'),
  language: require('./language.png'),
  abtChild: require('../abtChild.png'),
  teamBG: require('../teamBG.jpg'),
  union: require('../Union.png'),
  Illu: require('../Illu.png'),
  line: require('../Line3.png'),

  Child_1: require('../child_1.png'),
  Child_2: require('../child_2.png'),
  horseGrup: require('../childcare/horseGrup.png'),

  staff: require('../staff_assets/staff.png'),
  angel1: require('../staff_assets/angel1.png'),
  angel2: require('../staff_assets/angel2.png'),
  balloon: require('../staff_assets/baloon.png'),
  cloud1: require('../staff_assets/cloud1.png'),
  cloud2: require('../staff_assets/cloud2.png'),
  cloud3: require('../staff_assets/cloud3.png'),
  Lady: require('../staff_assets/Lady.png'),

  pressIcon: require('../pressIcon.png'),
  V: require('../V.png'),
  ct: require('../ct.png'),
  press1: require('../press1.png'),
  press2: require('../press2.png'),

  headLft: require('../CPR/head-lft.png'),
  headRyt: require('../CPR/head-ryt.png'),
  txt: require('../CPR/txt.png'),
  logoK: require('../logoK.png'),
  fb: require('../contact/fb.png'),
  insta: require('../contact/insta.png'),
  whtsap: require('../contact/wa.png'),
  walpaper: require('../contact/wallpaper.png'),
  videoBG: require('../video-sec.png'),
  videoIcon: require('../videoIcon.png'),
  castle: require('../castle.png'),
  enrollmentBG: require('../enrollment-bg.png'),
  enrollmentBGImg: require('../enrollmentBGImg.png'),
  graphBG: require('../graphBG.jpg'),

  cloudEyeclose: require('../tuition/cloudEyeclose.png'),
  kidbg: require('../tuition/kidbg.png'),
  rainbow: require('../tuition/rainbow.png'),
  readingKid: require('../tuition/readingKid.png'),
  tuitionkid1: require('../tuition/tuitionkid1.png'),
  tuitionkid2: require('../tuition/tuitionkid2.png'),
  tuitionkid3: require('../tuition/tuitionkid3.png'),
  whastapp: require('./whastapp.png'),
  pdfIcon: require('./pdfIcon.png'),
  bulletin: require('../bulletin.png'),
  lernmore_bgTP: require('../lernmore_bgTP.png'),
  lernmore_bgBtm: require('../lernmore_bgBtm.png'),
  c4k: require('../Care4Kids.png'),

  parkingBanner: require('../parkingPage/bannerImg.png'),
  parkingSlider1: require('../parkingPage/slider/Slider1.png'),
  parkingSlider2: require('../parkingPage/slider/Slider2.png'),
  parkingSlider3: require('../parkingPage/slider/Slider3.png'),
  parkingSlider4: require('../parkingPage/slider/Slider4.png'),
  parkingSlider5: require('../parkingPage/slider/Slider5.png'),
  parkingSlider6: require('../parkingPage/slider/Slider6.png'),
  parkingSlider7: require('../parkingPage/slider/Slider7.png'),
  parkingSlider8: require('../parkingPage/slider/Slider8.png'),
  parkingSlider9: require('../parkingPage/slider/Slider9.png'),

  evolveLogo: require('../parkingPage/evolveLogo.png'),
  evolveSlogan: require('../parkingPage/evolveSlogan.png'),
  PPLogo: require('../parkingPage/PPLogo.png'),
  videoThumb: require('../parkingPage/videoThumb.png'),

  evolveBannerBG: require('../evolveChildCare/bannerBG.jpg'),
  evolveBurgerIcon: require('../evolveChildCare/Burger.png'),
  evolveBannerLogo: require('../evolveChildCare/bannerLogo.png'),

  ppPlatformBannerBG: require('../ppPlatform/bannerBG.png'),
  ppPlatformBannerImg: require('../ppPlatform/imgHero.png'),

  parkingSlider10: require('../parkingPage/slider/Slider10.png'),
  parkingSlider11: require('../parkingPage/slider/Slider11.png'),
  digitalFacility: require('../ppPlatform/digitalFacility.png'),
  sliderImg: require('../ppPlatform/Img.png'),

  ppFeatures1: require('../ppPlatform/feature/feature_1.png'),
  ppFeatures2: require('../ppPlatform/feature/feature_2.png'),
  ppFeatures3: require('../ppPlatform/feature/feature_3.png'),
  ppFeatures4: require('../ppPlatform/feature/feature_4.png'),
  ppFeatures5: require('../ppPlatform/feature/feature_5.png'),

  efficiencyPopup: require('../ppPlatform/efficiencyPopup.png'),
  reportsPopup: require('../ppPlatform/reportsPopup.png'),

  ChildcareMaster: require('../ppPlatform/plans/ChildcareMaster.png'),
  GrowingStars: require('../ppPlatform/plans/GrowingStars.png'),
  BlossomingBuds: require('../ppPlatform/plans/BlossomingBuds.png'),
  BabySteps: require('../ppPlatform/plans/BabySteps.png'),

  promo1: require('../ppPlatform/promo1.png'),
  promo2: require('../ppPlatform/promo2.png'),
  promoMain: require('../ppPlatform/promoMain.png'),
  promoBG1: require('../ppPlatform/promoBG1.png'),
  promoBG2: require('../ppPlatform/promoBG2.png'),

  quote: require('../ppPlatform/quote.png'),
  star: require('../ppPlatform/star.png'),
  logo2: require('../ppPlatform/logo2.png'),

  platformSlider1: require('../ppPlatform/Slider/viewPlatform/slider1.png'),
  platformSlider2: require('../ppPlatform/Slider/viewPlatform/slider2.png'),
  platformSlider3: require('../ppPlatform/Slider/viewPlatform/slider3.png'),
  platformSlider4: require('../ppPlatform/Slider/viewPlatform/slider4.png'),
  platformSlider5: require('../ppPlatform/Slider/viewPlatform/slider5.png'),
  platformSlider6: require('../ppPlatform/Slider/viewPlatform/slider6.png'),
  platformSlider7: require('../ppPlatform/Slider/viewPlatform/slider7.png'),
  platformSlider8: require('../ppPlatform/Slider/viewPlatform/slider8.png'),

  websiteSlider1: require('../ppPlatform/Slider/viewWebsite/slider1.png'),
  websiteSlider2: require('../ppPlatform/Slider/viewWebsite/slider2.png'),
  websiteSlider3: require('../ppPlatform/Slider/viewWebsite/slider3.png'),
  websiteSlider4: require('../ppPlatform/Slider/viewWebsite/slider4.png'),
  websiteSlider5: require('../ppPlatform/Slider/viewWebsite/slider5.png'),
  websiteSlider6: require('../ppPlatform/Slider/viewWebsite/slider6.png'),
  websiteSlider7: require('../ppPlatform/Slider/viewWebsite/slider7.png'),
  websiteSlider8: require('../ppPlatform/Slider/viewWebsite/slider8.png'),
  websiteSlider9: require('../ppPlatform/Slider/viewWebsite/slider9.png'),
  websiteSlider10: require('../ppPlatform/Slider/viewWebsite/slider10.png'),
  websiteSlider11: require('../ppPlatform/Slider/viewWebsite/slider11.png'),
  websiteSlider12: require('../ppPlatform/Slider/viewWebsite/slider12.png'),
  websiteSlider13: require('../ppPlatform/Slider/viewWebsite/slider13.png'),
  websiteSlider14: require('../ppPlatform/Slider/viewWebsite/slider14.png'),
  websiteSlider15: require('../ppPlatform/Slider/viewWebsite/slider15.png'),
  websiteSlider16: require('../ppPlatform/Slider/viewWebsite/slider16.png'),
  websiteSlider17: require('../ppPlatform/Slider/viewWebsite/slider17.png'),
  websiteSlider18: require('../ppPlatform/Slider/viewWebsite/slider18.png'),
  websiteSlider19: require('../ppPlatform/Slider/viewWebsite/slider19.png'),
  websiteSlider20: require('../ppPlatform/Slider/viewWebsite/slider20.png'),
  websiteSlider21: require('../ppPlatform/Slider/viewWebsite/slider21.png'),
  websiteSlider22: require('../ppPlatform/Slider/viewWebsite/slider22.png'),

  placeholder: require('../placeholder.png'),
  inboxPlaceholder: require('../inboxPlaceholder.png'),
  parentPlaceholder: require('../parentPlaceholder.png'),
  paymentPlaceholder: require('../paymentPlaceholder.png'),

};
export default AppIcons;
/* eslint-enable global-require */
