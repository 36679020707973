import { useState } from 'react';
import propTypes from 'prop-types';

// @mui
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';

// css
import '../../../../assets/css/form.scss';
import SVGIcons from '../../../../assets/images/icons/svgIcons';

// Components
import { showSnackbar } from '../../../../components/snackbar';
import TextInput from '../../../../components/inputs/textInput/textInput';


// Helpers
import { getAuthId } from '../../../../helpers/auth.helper';
import { translateThis } from '../../../../helpers/language.helper';

// Services
import BasicDropdown from '../../../../components/dropdowns/basic.dropdown';

import './parentDetailForm.scss';
import { availableCountries } from '../../../../constants/countries';
import { updateProfile } from '../../../../services/api/auth';

// ----------------------------------------------------------------------


const ParentDetailForm = ({ onSuccess }) => {
    const parentId = getAuthId();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const isLoading = false;
    const [formData, setFormData] = useState({
        addressLineOne: '',
        addressLineTwo: '',
        phoneNumber: '',
        city: '',
        state: '',
        zipCode: '',
        country: {
            code: '+1',
            country: 'United States'
        },

    });


    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        const formDataAs = { ...formData };
        setFormData({ ...formDataAs, ...{ [name]: value } });
    }
    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData.addressLineOne
            && formData.phoneNumber
            && formData.city
            && formData.state
            && formData.zipCode
            && formData.country.code
        ) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        if (parentId && isValidForm()) {
            setIsSubmitting(true)
            const postData = { ...formData, country: formData.country.country, countryCode: formData.country.code };
            updateProfile(postData)
                .then((data) => {
                    if (data.success) {
                     showSnackbar().success('Profile updated successfully');
                    onSuccess();   
                    } else {
                        showSnackbar().failure(data.message)
                    }
                    
                })
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setIsSubmitting(false)
                })
        }

    };

    const handleCountryCodeSelection = (selectedCountry) => {
        const [country, code] = selectedCountry.split('$');
        setFormValue('country', {code, country});
    };

    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {!isLoading && <div>
                <Stack className='pp-contact-frm parent-detail-frm standard-form' spacing={1}>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextInput
                                    key={`parent_detail_form_Key_address`}
                                    label={translateThis('parent_detail_address')}
                                    name='addressLineOne'
                                    id='parent_detail_address'
                                    value={formData.addressLineOne}
                                    translationKey='parent_detail_address'
                                    type='text'
                                    required
                                    placeholder={translateThis('parent_detail_address')}
                                    className='custom-textfield'
                                    getValue={(name, value) => setFormValue(name, value)}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextInput
                                    key={`parent_detail_form_Key_addressLine2`}
                                    label={translateThis('parent_detail_addressLine2')}
                                    name='addressLineTwo'
                                    isRequired={false}
                                    id='parent_detail_addressLine2'
                                    value={formData.addressLineTwo}
                                    translationKey='parent_detail_addressLine2'
                                    type='text'
                                    placeholder={translateThis('parent_detail_addressLine2')}
                                    className='custom-textfield'
                                    getValue={(name, value) => setFormValue(name, value)}
                                    useDefaultValidation
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`parent_detail_form_Key_city`}
                                    label={translateThis('parent_detail_city')}
                                    name='city'
                                    id='parent_detail_city'
                                    value={formData.city}
                                    translationKey='parent_detail_city'
                                    type='text'
                                    required
                                    placeholder={translateThis('parent_detail_city')}
                                    className='custom-textfield'
                                    getValue={(name, value) => setFormValue(name, value)}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`parent_detail_form_Key_state`}
                                    label={translateThis('parent_detail_state')}
                                    name='state'
                                    id='parent_detail_state'
                                    value={formData.state}
                                    translationKey='parent_detail_state'
                                    type='text'
                                    required
                                    placeholder={translateThis('parent_detail_state')}
                                    className='custom-textfield'
                                    getValue={(name, value) => setFormValue(name, value)}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`parent_detail_form_Key_zipcode`}
                                    label={translateThis('parent_detail_zipcode')}
                                    name='zipCode'
                                    id='parent_detail_zipcode'
                                    value={formData.zipCode}
                                    translationKey='parent_detail_zipcode'
                                    type='text'
                                    required
                                    placeholder={translateThis('parent_detail_zipcode')}
                                    className='custom-textfield'
                                    getValue={(name, value) => setFormValue(name, value)}
                                    useDefaultValidation
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <div className='phoneWithCode-box'>
                                    <BasicDropdown
                                        key={formData.countryCode}
                                        // label="Choose Form"
                                        id='selectCountryCode'
                                        options={availableCountries}
                                        onChange={(value) => handleCountryCodeSelection(value)}
                                        value={'United States$+1'}
                                    />
                                    <TextInput
                                        key={`phoneNumber`}
                                        label={translateThis('phoneNumber')}
                                        name='phoneNumber'
                                        id='phoneNumber'
                                        value={formData.phoneNumber}
                                        translationKey='phoneNumber'
                                        type='number'
                                        required
                                        placeholder={translateThis('phoneNumber')}
                                        className='custom-textfield'
                                        getValue={(name, value) => setFormValue(name, value)}
                                        useDefaultValidation
                                    />


                                </div>
                            </Grid>
                        </Grid>
                        <br />
                        <LoadingButton
                            loading={isSubmitting}
                            loadingPosition='start'
                            startIcon={<></>} // To remove Mui warning
                            fullWidth
                            disabled={!isValidForm()}
                            size="large"
                            type="submit"
                            variant="contained"
                            className='login-btn fill-btn'
                            onClick={onSubmit}>
                            {translateThis('Save')}
                        </LoadingButton>
                    </form>

                </Stack>
            </div>}
        </>
    );
}

ParentDetailForm.propTypes = {
    onSuccess: propTypes.func
}

export default ParentDetailForm;